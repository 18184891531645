import React, { useEffect, useRef } from "react";
import { Card, Image, Text, Title } from "@mantine/core";
import { useTranslation } from "react-i18next";

export const Trainings = (props) => {
  const { t, i18n } = useTranslation();

  const values = [
    {
      num: "1",
      heading: t("trainings.head1"),
      paragraph: t("trainings.par1"),
      imgSrc: "img/fatherhood.jpg",
    },
    {
      num: "2",
      heading: t("trainings.head2"),
      paragraph: t("trainings.par2"),
      imgSrc: "img/Multiply School Logo.jpg",
    },
    {
      num: "3",
      heading: t("trainings.head3"),
      paragraph: t("trainings.par3"),
      imgSrc: "img/ReadySetGO logo.png",
    },
  ];

  const cardRefs = useRef([]);
  const contRef = useRef();

  useEffect(() => {
    const fadeInCards = () => {
      cardRefs.current.forEach((ref, index) => {
        if (isInViewport(contRef.current)) {
          setTimeout(() => {
            ref.classList.add("fade-in");
          }, index * 200); // Adjust the delay time as needed
        }
      });
    };

    const isInViewport = (element) => {
      const rect = element.getBoundingClientRect();
      return (
        rect.top >= 0 &&
        rect.left >= 0 &&
        rect.bottom <=
        (window.innerHeight || document.documentElement.clientHeight) &&
        rect.right <=
        (window.innerWidth || document.documentElement.clientWidth)
      );
    };

    window.addEventListener("scroll", fadeInCards);
    fadeInCards(); // Check on initial load

    return () => {
      window.removeEventListener("scroll", fadeInCards);
    };
  }, []);

  return (
    <div id="trainings" className="text-center">
      <div className="container">
        <h1 ref={contRef}>{t("trainings.title")}</h1>
        <div className="cardCont">
          {values.map((item, index) => (
            <div
              key={item.num}
              className="cardDiv"
              ref={(el) => (cardRefs.current[index] = el)}
            >
              <Card
                shadow="xl"
                padding="xl"
                bg="#5e9efc"
                c="#fff"
                w={652}
                h={754}
                component="div"
                className="card"
              >
                <Card.Section>
                  <Image src={item.imgSrc} mb={40} h={512} alt={item.heading} />
                </Card.Section>

                <Title fw={500} size="lg" order={2}>
                  {item.heading}
                </Title>

                <Text mt="xs" c="dimmed" size="sm">
                  {item.paragraph}
                </Text>
              </Card>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};
