import React from "react";
import classes from "./movementSigns.css";
import {Accordion, Container, Grid} from "@mantine/core";
import './movementSigns.css'
import {useTranslation} from "react-i18next";

export const Values = () => {

  const { t, i18n} = useTranslation();

  const values = [
    {
      heading: t("values.head1"),
      paragraph: t("values.par1"),
    },
    {
      heading: t("values.head2"),
      paragraph: t("values.par2"),
    },
    {
      heading: t("values.head3"),
      paragraph: t("values.par3"),
    },
    {
      heading: t("values.head4"),
      paragraph: t("values.par4"),
    },
    {
      heading: t("values.head5"),
      paragraph: t("values.par5"),
    },
    {
      heading: t("values.head6"),
      paragraph: t("values.par6"),
    },
    {
      heading: t("values.head7"),
      paragraph: t("values.par7"),
    },
    {
      heading: t("values.head8"),
      paragraph: t("values.par8"),
    },
    {
      heading: t("values.head9"),
      paragraph: t("values.par9"),
    },
  ]

  return (
    <div id="values" className="text-center">
      <div className="container">
        <h1>{t("values.title")}</h1>
        <div className={classes.wrapper}>
          <Container className="w-3/5 xl:w-full" style={{maxWidth: 'none'}}>
            <Grid id="values-grid" gutter={80}>
              <Grid.Col span={{ base: 18, lg: 13 }}>
                <Accordion chevronPosition="right" chevron={<i className='fa fa-plus'></i>} variant="separated">
                  {values.map((item, index) =>
                      <Accordion.Item  key={index} className={classes.item} value={`${index}`}>
                        <Accordion.Control>{item.heading}</Accordion.Control>
                        <Accordion.Panel>{item.paragraph}</Accordion.Panel>
                      </Accordion.Item>
                  )}
                </Accordion>
              </Grid.Col>
            </Grid>
          </Container>
        </div>
      </div>
    </div>
  );
};