import React, {forwardRef, useEffect, useRef} from 'react';
import { useTranslation } from 'react-i18next';

const SingleCard = ({ item, index, contRef }) => {
  const { t } = useTranslation();
  const cardRef = useRef(null);

  useEffect(() => {
    const fadeInCard = () => {
      if (isInViewport(contRef.current)) {
        setTimeout(() => {
          cardRef.current.classList.add('fade-in');
        }, index * 200); // Adjust the delay time as needed
        window.removeEventListener('scroll', fadeInCard);
      }
    };

    const isInViewport = (element) => {
      const rect = element.getBoundingClientRect();
      return (
        rect.top >= 0 &&
        rect.left >= 0 &&
        rect.bottom <= (window.innerHeight || document.documentElement.clientHeight) &&
        rect.right <= (window.innerWidth || document.documentElement.clientWidth)
      );
    };

    window.addEventListener('scroll', fadeInCard);
    fadeInCard(); // Check on initial load

    return () => {
      window.removeEventListener('scroll', fadeInCard);
    };
  }, [index]);

  return (
    <div ref={cardRef} className='hover:bg-blue-950 hover:duration-500 bg-white color-white h-128 rounded-3xl opacity-0 mt-12 py-20 px-14 shadow-xl group'>
      <h4 className='text-3xl xl:text-5xl lg:text-4xl md:text-4xl mds:text-6xl font-semibold text-left text-black mb-14 group-hover:text-white'>{item.heading}</h4>
      <img src={item.imgSrc} alt={item.imgSrc} width={100} height={100} className="mb-12" />
      <h4 className='text-xl xl:text-2xl lg:text-xl mds:text-3xl font-normal text-black text-left group-hover:text-slate-200 mb-5'>{item.paragraph}</h4>
    </div>
  );
};

export const Card = forwardRef(({ data, contRef }) => {

  return (
    <div id="card" className="text-center">
      <div className='grid grid-cols-1 lg:grid-cols-3 my-16 gap-x-16 lg:gap-x-32'>
        {data.map((item, i) => (
          <SingleCard key={i} index={i} item={item} contRef={contRef}/>
        ))}
      </div>
    </div>
  );
});