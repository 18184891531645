import React, {useEffect, useRef, useState} from "react";
import {useTranslation} from "react-i18next";
import {FaGlobe} from "react-icons/fa";

export const Navigation = (props) => {

  const [isOpen, setIsOpen] = useState(false);
  const [isNavOpen, setIsNavOpen] = useState(false);
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const dropdownRef = useRef(null);
  const { t, i18n } = useTranslation();

  useEffect(() => {
    function handleClickOutside(event) {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setIsOpen(false);
      }
    }

    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener('resize', handleResize);
    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      window.removeEventListener('resize', handleResize);
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const handleOptionChange = (option) => {
    i18n.changeLanguage(option);
    setIsOpen(false);
    setIsNavOpen(false)
  }

  const handleMenuItemClick = () => {
    setIsNavOpen(false);
  };

  return (
    <nav id="menu" className="navbar navbar-default navbar-fixed-top">
      <div className="container">
        <div className="navbar-header">
          <button
            type="button"
            className="navbar-toggle collapsed"
            onClick={() => setIsNavOpen(!isNavOpen)}
          >
            <span className="sr-only">Toggle navigation</span>
            <span className="icon-bar"></span>
            <span className="icon-bar"></span>
            <span className="icon-bar"></span>
          </button>
          <a className="navbar-brand page-scroll" href="#page-top">
            {t("nav.brand")}
          </a>
        </div>

        <div className={"collapse navbar-collapse" + (isNavOpen ? " in" : "")} style={{visibility: isNavOpen || windowWidth > 500 ? 'visible' : 'hidden', overflowX: 'hidden'}}>
          <ul className="nav navbar-nav navbar-right navMenu">
            <li>
              <a href="#counter" className="page-scroll" onClick={handleMenuItemClick}>
                {t("nav.vision")}
              </a>
            </li>
            <li>
              <a href="#movementSigns" className="page-scroll" onClick={handleMenuItemClick}>
                {t("nav.signs")}
              </a>
            </li>
            <li>
              <a href="#whatIsIt" className="page-scroll" onClick={handleMenuItemClick}>
                {t("nav.whatisit")}
              </a>
            </li>
            <li>
              <a href="#values" className="page-scroll" onClick={handleMenuItemClick}>
                {t("nav.values")}
              </a>
            </li>
            <li>
              <a href="#principles" className="page-scroll" onClick={handleMenuItemClick}>
                {t("nav.principles")}
              </a>
            </li>
            <li>
              <a href="#map" className="page-scroll" onClick={handleMenuItemClick}>
                {t("nav.map")}
              </a>
            </li>
            <li>
              <a href="#trainings" className="page-scroll" onClick={handleMenuItemClick}>
                {t("nav.trainings")}
              </a>
            </li>
            <li>
              <a href="#contact" className="page-scroll" onClick={handleMenuItemClick}>
                {t("nav.contact")}
              </a>
            </li>
            <li>
              {windowWidth < 768 ?
                <div className="-right-16 mt-2 w-48 bg-white rounded-lg shadow-lg flex flex-wrap w-[90%] mds:w-[60%]">
                  <a className="block px-2 py-2 text-gray-800 hover:bg-blue-primary hover:text-white" onClick={() => handleOptionChange('ru')}><span className='countryFlag'>🇷🇺</span></a>
                  <a className="block px-2 py-2 text-gray-800 hover:bg-blue-primary hover:text-white" onClick={() => handleOptionChange('ua')}><span className='countryFlag'>🇺🇦</span></a>
                  <a className="block px-2 py-2 text-gray-800 hover:bg-blue-primary hover:text-white" onClick={() => handleOptionChange('en')}><span className='countryFlag'>🇬🇧</span></a>
                  <a className="block px-2 py-2 text-gray-800 hover:bg-blue-primary hover:text-white" onClick={() => handleOptionChange('de')}><span className='countryFlag'>🇩🇪</span></a>
                  <a className="block px-2 py-2 text-gray-800 hover:bg-blue-primary hover:text-white" onClick={() => handleOptionChange('it')}><span className='countryFlag'>🇮🇹</span></a>
                  <a className="block px-2 py-2 text-gray-800 hover:bg-blue-primary hover:text-white" onClick={() => handleOptionChange('pl')}><span className='countryFlag'>🇵🇱</span></a>
                  <a className="block px-2 py-2 text-gray-800 hover:bg-blue-primary hover:text-white" onClick={() => handleOptionChange('sr')}><span className='countryFlag'>🇷🇸</span></a>
                  <a className="block px-2 py-2 text-gray-800 hover:bg-blue-primary hover:text-white" onClick={() => handleOptionChange('sl')}><span className='countryFlag'>🇸🇮</span></a>
                  <a className="block px-2 py-2 text-gray-800 hover:bg-blue-primary hover:text-white" onClick={() => handleOptionChange('sq')}><span className='countryFlag'>🇦🇱</span></a>
                  <a className="block px-2 py-2 text-gray-800 hover:bg-blue-primary hover:text-white" onClick={() => handleOptionChange('bs')}><span className='countryFlag'>🇧🇦</span></a>
                  <a className="block px-2 py-2 text-gray-800 hover:bg-blue-primary hover:text-white" onClick={() => handleOptionChange('mk')}><span className='countryFlag'>🇲🇰</span></a>
                  <a className="block px-2 py-2 text-gray-800 hover:bg-blue-primary hover:text-white" onClick={() => handleOptionChange('ro')}><span className='countryFlag'>🇷🇴</span></a>
                  <a className="block px-2 py-2 text-gray-800 hover:bg-blue-primary hover:text-white" onClick={() => handleOptionChange('bg')}><span className='countryFlag'>🇧🇬</span></a>
                  <a className="block px-2 py-2 text-gray-800 hover:bg-blue-primary hover:text-white" onClick={() => handleOptionChange('tr')}><span className='countryFlag'>🇹🇷</span></a>
                  <a className="block px-2 py-2 text-gray-800 hover:bg-blue-primary hover:text-white" onClick={() => handleOptionChange('hu')}><span className='countryFlag'>🇭🇺</span></a>
                  <a className="block px-2 py-2 text-gray-800 hover:bg-blue-primary hover:text-white" onClick={() => handleOptionChange('el')}><span className='countryFlag'>🇬🇷</span></a>
                </div>
                :
                <div className="relative" ref={dropdownRef}>
                  <button className="flex items-center justify-center lg:w-10 lg:h-10 w-7 h-7 text-[9px] lg:text-[12px] mt-7 lg:mt-6 bg-blue-primary rounded-full focus:outline-none"
                        onClick={() => setIsOpen(!isOpen)}
                  >
                    <FaGlobe className="text-white" />
                  </button>
                  {isOpen && (
                    <div className="absolute right-0 mt-2 w-48 bg-white rounded-lg shadow-lg">
                      <a className="block px-4 py-2 text-gray-800 hover:bg-blue-primary hover:text-white" onClick={() => handleOptionChange('ru')}><span className='countryFlag'>🇷🇺</span>{t("nav.russian")}</a>
                      <a className="block px-4 py-2 text-gray-800 hover:bg-blue-primary hover:text-white" onClick={() => handleOptionChange('ua')}><span className='countryFlag'>🇺🇦</span>{t("nav.ukrainian")}</a>
                      <a className="block px-4 py-2 text-gray-800 hover:bg-blue-primary hover:text-white" onClick={() => handleOptionChange('en')}><span className='countryFlag'>🇬🇧</span>{t("nav.english")}</a>
                      <a className="block px-4 py-2 text-gray-800 hover:bg-blue-primary hover:text-white" onClick={() => handleOptionChange('de')}><span className='countryFlag'>🇩🇪</span>{t("nav.german")}</a>
                      <a className="block px-4 py-2 text-gray-800 hover:bg-blue-primary hover:text-white" onClick={() => handleOptionChange('it')}><span className='countryFlag'>🇮🇹</span>{t("nav.italian")}</a>
                      <a className="block px-4 py-2 text-gray-800 hover:bg-blue-primary hover:text-white" onClick={() => handleOptionChange('pl')}><span className='countryFlag'>🇵🇱</span>{t("nav.polish")}</a>
                      <a className="block px-4 py-2 text-gray-800 hover:bg-blue-primary hover:text-white" onClick={() => handleOptionChange('sr')}><span className='countryFlag'>🇷🇸</span>{t("nav.serbian")}</a>
                      <a className="block px-4 py-2 text-gray-800 hover:bg-blue-primary hover:text-white" onClick={() => handleOptionChange('sl')}><span className='countryFlag'>🇸🇮</span>{t("nav.slovenian")}</a>
                      <a className="block px-4 py-2 text-gray-800 hover:bg-blue-primary hover:text-white" onClick={() => handleOptionChange('sq')}><span className='countryFlag'>🇦🇱</span>{t("nav.albanian")}</a>
                      <a className="block px-4 py-2 text-gray-800 hover:bg-blue-primary hover:text-white" onClick={() => handleOptionChange('bs')}><span className='countryFlag'>🇧🇦</span>{t("nav.bosnian")}</a>
                      <a className="block px-4 py-2 text-gray-800 hover:bg-blue-primary hover:text-white" onClick={() => handleOptionChange('mk')}><span className='countryFlag'>🇲🇰</span>{t("nav.macedonian")}</a>
                      <a className="block px-4 py-2 text-gray-800 hover:bg-blue-primary hover:text-white" onClick={() => handleOptionChange('ro')}><span className='countryFlag'>🇷🇴</span>{t("nav.romanian")}</a>
                      <a className="block px-4 py-2 text-gray-800 hover:bg-blue-primary hover:text-white" onClick={() => handleOptionChange('bg')}><span className='countryFlag'>🇧🇬</span>{t("nav.bulgarian")}</a>
                      <a className="block px-4 py-2 text-gray-800 hover:bg-blue-primary hover:text-white" onClick={() => handleOptionChange('tr')}><span className='countryFlag'>🇹🇷</span>{t("nav.turkish")}</a>
                      <a className="block px-4 py-2 text-gray-800 hover:bg-blue-primary hover:text-white" onClick={() => handleOptionChange('hu')}><span className='countryFlag'>🇭🇺</span>{t("nav.hungarian")}</a>
                      <a className="block px-4 py-2 text-gray-800 hover:bg-blue-primary hover:text-white" onClick={() => handleOptionChange('el')}><span className='countryFlag'>🇬🇷</span>{t("nav.greek")}</a>
                    </div>
                  )}
                </div>
              }
            </li>
          </ul>

        </div>
      </div>
    </nav>
  );
};
