import React, {useEffect, useRef, useState} from "react";
import {useTranslation} from "react-i18next";
const $ = require('jquery')

export const Counter = (props) => {
  const { t, i18n} = useTranslation();
  const [isVisible, setIsVisible] = useState(false);
  const countRef = useRef();

  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        // Update state based on whether the component is in the viewport
        setIsVisible(entry.isIntersecting);
      },
      {
        // Set rootMargin to define the area around the viewport to trigger intersection
        rootMargin: '0px',
        // You can adjust the threshold to define at what point of visibility the callback should trigger
        threshold: 0.5, // 0 means as soon as even one pixel is visible, 1 means when the entire element is visible
      }
    );

    if (countRef.current) {
      observer.observe(countRef.current);
    }

    // Cleanup
    return () => {
      if (countRef.current) {
        observer.unobserve(countRef.current);
      }
    };
  }, []);

  useEffect(() => {
    if (isVisible) {
      $('.counting').each(function() {
        let $this = $(this),
          countTo = $this.attr('data-count');

        $({ countNum: $this.text()}).animate({
            countNum: countTo
          },
          {
            duration: 1000,
            easing:'linear',
            step: function() {
              $this.text(Math.floor(this.countNum));
            },
            complete: function() {
              $this.text(this.countNum);
            }
          });
      });
    }
  }, [isVisible]);


  return (
    <div id="counter" className="text-center">
      <div className="container">
        <h1 className="text-70 xl:text-70 lg:text-8xl md:text-8xl sm:text-7xl">{t("counter.title")}</h1>
        <section id="counter-stats" className="wow fadeInRight" data-wow-duration="1.4s">


          <div className="col-lg-3 stats">
            <i className="fa fa-building-o" aria-hidden="true"></i>
            <div className="counting" data-count="1314" ref={countRef}>0</div>
            <h2>{t("counter.stat1")}</h2>
          </div>

          <div className="col-lg-3 stats">
            <i className="fa fa-users" aria-hidden="true"></i>
            <div className="counting" data-count="144">0</div>
            <h2>{t("counter.stat2")}</h2>
          </div>

          <div className="col-lg-3 stats">
            <i className="fa fa-user" aria-hidden="true"></i>
            <div className="counting" data-count="63.5">0</div>
            <h2>{t("counter.stat3")}</h2>
          </div>
        </section>
        <p>{t("counter.ps")}</p>
      </div>
    </div>
  );
};