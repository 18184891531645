import React from "react";
import SmoothScroll from "smooth-scroll";
import { MantineProvider } from '@mantine/core';
import { Navigation } from "./components/navigation";
import { Header } from "./components/header";
import { Contact } from "./components/contact";
import {Counter} from "./components/counter";
import {MovementSigns} from "./components/movementSigns";
import {WhatIsIt} from "./components/whatIsIt";
import {Diagram} from "./components/diagram";
import {Values} from "./components/values";
import {Principles} from "./components/principles";
import {Map} from "./components/map";
import {Trainings} from "./components/trainings";
import "./App.css";
import '@mantine/core/styles.css';

export const scroll = new SmoothScroll('a[href*="#"]', {
  speed: 1000,
  speedAsDuration: true,
});

const App = () => {
  return (
    <MantineProvider>
      <div className="mainCont">
        <Navigation />
        <Header />
        <Counter />
        <MovementSigns />
        <WhatIsIt />
        <Diagram />
        <Values />
        <Principles />
        <Map />
        <Trainings />
        <Contact />
      </div>
    </MantineProvider>
  );
};

export default App;
