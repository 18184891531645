import React, {useEffect} from "react";
import mapboxgl from 'mapbox-gl';
import { Layer, Feature } from "react-mapbox-gl";
import {useTranslation} from "react-i18next";

export const Map = (props) => {

  const { t, i18n} = useTranslation();

  const teamsMarker = [
  //   {
  //     num: '1',
  //     city: t("map.popup1"),
  //     coordinates: [30.5, 50.5],
  //   },
  //   {
  //     num: '2',
  //     city: t("map.popup2"),
  //     coordinates: [44.51, 40.2],
  //   }
  ]



  useEffect(() => {
    mapboxgl.accessToken = 'pk.eyJ1IjoiYXJtZnJwbCIsImEiOiJjbDNvNnVkbGswYnllM2Nxc2U0OGd1dnNoIn0.L9-Fa4kufVAVAjLMVNmGqA';
    const map = new mapboxgl.Map({
      container: 'mapcont', // container ID
      style: 'mapbox://styles/mapbox/streets-v12', // style URL
      center: [19.095266, 42.098113], // starting position [lng, lat]
      zoom: 5, // starting zoom
      projection: 'mercator'
    });
    const popup = new mapboxgl.Popup({ offset: 35, closeButton: false }).setHTML(
      `<h3 style="color: #172554; font-size: 15px; ">${t("map.popup")}</h3>`
    ).addClassName('popup');
    const popup1 = new mapboxgl.Popup({ offset: 35, closeButton: false }).setHTML(
      `<h3 style="color: #172554; font-size: 15px; ">${t("map.popup1")}</h3>`
    ).addClassName('popup');
    const popup2 = new mapboxgl.Popup({ offset: 35, closeButton: false }).setHTML(
      `<h3 style="color: #172554; font-size: 15px; ">${t("map.popup2")}</h3>`
    ).addClassName('popup');
    // Set marker options.
    // const marker = new mapboxgl.Marker({
    //   color: "#5e9efc",
    // }).setLngLat([19.095266, 42.098113])
    //   .setPopup(popup)
    //   .addTo(map);
    const marker1 = new mapboxgl.Marker({
      color: "#5e9efc",
    }).setLngLat([19.2594, 42.4304])
      .setPopup(popup1)
      .addTo(map);
    const marker2 = new mapboxgl.Marker({
      color: "#5e9efc",
    }).setLngLat([18.9562, 42.7805])
      .setPopup(popup2)
      .addTo(map);
    [marker1,marker2].forEach((item) => {
    item.getElement().addEventListener('mouseenter', () => {
        item.togglePopup();
    })});
    [marker1,marker2].forEach((item) => {
      item.getElement().addEventListener('mouseleave', () => {
        item.togglePopup();
      })});


    map.on('load', function () {
      map.addSource('cross-icon', {
        'type': 'geojson',
        'data': {
          'type': 'FeatureCollection',
          'features': [{
            'type': 'Feature',
            'properties': {
              'description': t("map.popup")
            },
            'geometry': {
              'type': 'Point',
              'coordinates': [19.095266, 42.098113]
            }
          }]
        }
      });

      map.loadImage('img/cross.png', function (error, image) {
        if (error) throw error;
        map.addImage('cross-icon', image);

        map.addLayer({
          'id': 'cross',
          'type': 'symbol',
          'source': 'cross-icon',
          'layout': {
            'icon-image': 'cross-icon',
            'icon-size': .05
          }
        });
      });
    });

    map.on('load', () => {
      map.addSource('places', {
        'type': 'geojson',
        'data': {
          'type': 'FeatureCollection',
          'features': [
            {
              'type': 'Feature',
              'properties': {
                'description': t("map.popup3")
              },
              'geometry': {
                'type': 'Point',
                'coordinates': [18.8403, 42.2911]
              }
            },
            {
              'type': 'Feature',
              'properties': {
                'description': t("map.popup4")
              },
              'geometry': {
                'type': 'Point',
                'coordinates': [18.6986, 42.4319]
              }
            },
            {
              'type': 'Feature',
              'properties': {
                'description':t("map.popup5")
              },
              'geometry': {
                'type': 'Point',
                'coordinates': [18.5315, 42.4572]
              }
            },
            {
              'type': 'Feature',
              'properties': {
                'description': t("map.popup8")
              },
              'geometry': {
                'type': 'Point',
                'coordinates': [19.2148, 41.9311]
              }
            },
            {
              'type': 'Feature',
              'properties': {
                'description': t("map.popup6")
              },
              'geometry': {
                'type': 'Point',
                'coordinates': [18.9116, 42.3931]
              }
            },
            {
              'type': 'Feature',
              'properties': {
                'description': t("map.popup7")
              },
              'geometry': {
                'type': 'Point',
                'coordinates': [19.8187, 41.3275]
              }
            },
            {
              'type': 'Feature',
              'properties': {
                'description': t("map.popup9")
              },
              'geometry': {
                'type': 'Point',
                'coordinates': [18.4131, 43.8563]
              }
            },
            {
              'type': 'Feature',
              'properties': {
                'description': t("map.popup10")
              },
              'geometry': {
                'type': 'Point',
                'coordinates': [15.9819, 45.8150]
              }
            },
            {
              'type': 'Feature',
              'properties': {
                'description': t("map.popup11")
              },
              'geometry': {
                'type': 'Point',
                'coordinates': [18.0944, 42.6507]
              }
            },
            {
              'type': 'Feature',
              'properties': {
                'description': t("map.popup12")
              },
              'geometry': {
                'type': 'Point',
                'coordinates': [18.6955, 45.5550]
              }
            },
            {
              'type': 'Feature',
              'properties': {
                'description': t("map.popup13")
              },
              'geometry': {
                'type': 'Point',
                'coordinates': [16.4435, 43.5147]
              }
            },
            {
              'type': 'Feature',
              'properties': {
                'description': t("map.popup14")
              },
              'geometry': {
                'type': 'Point',
                'coordinates': [14.5058, 46.0569]
              }
            },
            {
              'type': 'Feature',
              'properties': {
                'description': t("map.popup15")
              },
              'geometry': {
                'type': 'Point',
                'coordinates': [20.4612, 44.8125]
              }
            },
            {
              'type': 'Feature',
              'properties': {
                'description': t("map.popup16")
              },
              'geometry': {
                'type': 'Point',
                'coordinates': [19.8335, 45.2671]
              }
            },
            {
              'type': 'Feature',
              'properties': {
                'description': t("map.popup17")
              },
              'geometry': {
                'type': 'Point',
                'coordinates': [21.2087, 45.7489]
              }
            },
            {
              'type': 'Feature',
              'properties': {
                'description': t("map.popup18")
              },
              'geometry': {
                'type': 'Point',
                'coordinates': [26.1025, 44.4268]
              }
            },
            {
              'type': 'Feature',
              'properties': {
                'description': t("map.popup19")
              },
              'geometry': {
                'type': 'Point',
                'coordinates': [23.3219, 42.6977]
              }
            },
            {
              'type': 'Feature',
              'properties': {
                'description': t("map.popup20")
              },
              'geometry': {
                'type': 'Point',
                'coordinates': [27.4626, 42.5048]
              }
            },
            {
              'type': 'Feature',
              'properties': {
                'description': t("map.popup21")
              },
              'geometry': {
                'type': 'Point',
                'coordinates': [27.8273, 43.5726]
              }
            },
            {
              'type': 'Feature',
              'properties': {
                'description': t("map.popup22")
              },
              'geometry': {
                'type': 'Point',
                'coordinates': [27.9147, 43.2141]
              }
            },
            {
              'type': 'Feature',
              'properties': {
                'description': t("map.popup23")
              },
              'geometry': {
                'type': 'Point',
                'coordinates': [21.4254, 41.9981]
              }
            },
            {
              'type': 'Feature',
              'properties': {
                'description': t("map.popup24")
              },
              'geometry': {
                'type': 'Point',
                'coordinates': [19.9437, 40.7086]
              }
            },
            {
              'type': 'Feature',
              'properties': {
                'description': t("map.popup25")
              },
              'geometry': {
                'type': 'Point',
                'coordinates': [19.4914, 40.4661]
              }
            },
            {
              'type': 'Feature',
              'properties': {
                'description': t("map.popup26")
              },
              'geometry': {
                'type': 'Point',
                'coordinates': [22.9444, 40.6401]
              }
            },
            {
              'type': 'Feature',
              'properties': {
                'description': t("map.popup27")
              },
              'geometry': {
                'type': 'Point',
                'coordinates': [23.7275, 37.9838]
              }
            },
            {
              'type': 'Feature',
              'properties': {
                'description': t("map.popup28")
              },
              'geometry': {
                'type': 'Point',
                'coordinates': [24.7453, 42.1354]
              }
            },
            {
              'type': 'Feature',
              'properties': {
                'description': t("map.popup29")
              },
              'geometry': {
                'type': 'Point',
                'coordinates': [26.5557, 41.6771]
              }
            },
            {
              'type': 'Feature',
              'properties': {
                'description': t("map.popup30")
              },
              'geometry': {
                'type': 'Point',
                'coordinates': [27.8137, 41.1558]
              }
            },
            {
              'type': 'Feature',
              'properties': {
                'description': t("map.popup31")
              },
              'geometry': {
                'type': 'Point',
                'coordinates': [28.9784, 41.0082]
              }
            },
            {
              'type': 'Feature',
              'properties': {
                'description': t("map.popup32")
              },
              'geometry': {
                'type': 'Point',
                'coordinates': [28.6519, 44.1759]
              }
            },
          ]
        }
      });
      // Add a layer showing the places.
      map.addLayer({
        'id': 'places',
        'type': 'circle',
        'source': 'places',
        'paint': {
          'circle-color': '#fff',
          'circle-radius': 6,
          'circle-stroke-width': 2,
          'circle-stroke-color': '#5e9efc'
        }
      });

      // Create a popup, but don't add it to the map yet.

      const popup3 = new mapboxgl.Popup({
        closeButton: false,
        closeOnClick: false,
        offset: 8
      });

      map.on('mouseenter', 'places', (e) => {
        map.getCanvas().style.cursor = 'pointer';

        const coordinates = e.features[0].geometry.coordinates.slice();
        const description = e.features[0].properties.description;

        while (Math.abs(e.lngLat.lng - coordinates[0]) > 180) {
          coordinates[0] += e.lngLat.lng > coordinates[0] ? 360 : -360;
        }

        popup3.setLngLat(coordinates).setHTML(description).addTo(map);
      });

      map.on('mouseleave', 'places', () => {
        map.getCanvas().style.cursor = '';
        popup3.remove();
      });
    });

    const popup4 = new mapboxgl.Popup({
      closeButton: false,
      closeOnClick: false,
      offset: 12
    });

    map.on('mouseenter', 'cross', (e) => {
      map.getCanvas().style.cursor = 'pointer';

      const coordinates = e.features[0].geometry.coordinates.slice();
      const description = e.features[0].properties.description;

      while (Math.abs(e.lngLat.lng - coordinates[0]) > 180) {
        coordinates[0] += e.lngLat.lng > coordinates[0] ? 360 : -360;
      }

      popup4.setLngLat(coordinates).setHTML(description).addTo(map);
    });

    map.on('mouseleave', 'cross', () => {
      map.getCanvas().style.cursor = '';
      popup4.remove();
    });
  })


  return (
    <div id="map" className="text-center">
      <div className="container">
        <h1>{t("map.title")}</h1>
        <div id="mapcont" style={{height: '600px'}} />
        <div className="hintCont">
          <div>
            <img src="img/cross.png" alt="Cross"/>
            <p>{t("map.hintCross")}</p>
          </div>
          <div>
            <img src="img/marker.png" alt="Marker"/>
            <p>{t("map.hintMarker")}</p>
          </div>
          <div>
            <img src="img/circle.png" alt="Circle"/>
            <p>{t("map.hintCircle")}</p>
          </div>
        </div>
      </div>
    </div>
  );
};