import React from "react";
import {Container, Accordion, Grid} from '@mantine/core';
import classes from './movementSigns.css';
import {useTranslation} from "react-i18next";

export const MovementSigns = (props) => {

  const { t, i18n} = useTranslation();

  const values = [
    {
      heading: t("signs.heading1"),
      isHead: t("signs.isHead"),
      isPar: t("signs.isPar1"),
      isNotHead: t("signs.isNotHead"),
      isNotPar: t("signs.isNotPar1"),
    },
    {
      heading: t("signs.heading2"),
      isHead: t("signs.isHead"),
      isPar: t("signs.isPar2"),
      isNotHead: t("signs.isNotHead"),
      isNotPar: t("signs.isNotPar2"),
    },
    {
      heading: t("signs.heading3"),
      isHead: t("signs.isHead"),
      isPar: t("signs.isPar3"),
      isNotHead: t("signs.isNotHead"),
      isNotPar: t("signs.isNotPar3"),
    },
    {
      heading: t("signs.heading4"),
      isHead: t("signs.isHead"),
      isPar: t("signs.isPar4"),
      isNotHead: t("signs.isNotHead"),
      isNotPar: t("signs.isNotPar4"),
    },
  ]

  return (
    <div id="movementSigns" className="text-center">
      <div className="container">
        <h1>{t("signs.title")}</h1>
        <p>{t("signs.subtitle")}</p>
        {
          values.map((item, index) =>
          <div key={index}>
            <h2>{item.heading}</h2>
            <div>
              <Container className="w-3/5 xl:w-full" style={{maxWidth: 'none'}}>
                <Grid id="faq-grid" gutter={80}>
                  <Grid.Col span={{ base: 18, md: 6 }}>
                    <Accordion chevronPosition="right" chevron={<i className='fa fa-chevron-down'></i>} variant="separated">
                      <Accordion.Item value="isMovementSign">
                        <Accordion.Control style={{fontSize: '20px'}}>{item.isHead}</Accordion.Control>
                        <Accordion.Panel style={{whiteSpace: 'pre-line'}}>{item.isPar}</Accordion.Panel>
                      </Accordion.Item>
                    </Accordion>
                  </Grid.Col>
                  <Grid.Col span={{ base: 18, md: 6 }}>
                    <Accordion chevronPosition="right" chevron={<i className='fa fa-chevron-down'></i>} variant="separated">
                      <Accordion.Item value="isNotMovementSign">
                        <Accordion.Control style={{fontSize: '20px'}}>{item.isNotHead}</Accordion.Control>
                        <Accordion.Panel style={{whiteSpace: 'pre-line'}}>{item.isNotPar}</Accordion.Panel>
                      </Accordion.Item>
                    </Accordion>
                  </Grid.Col>
                </Grid>
              </Container>
            </div>
          </div>
          )
        }
      </div>
    </div>
  );
};