import React, { useState } from "react";
import axios from "axios";
import {FaTelegramPlane, FaViber, FaWhatsapp} from "react-icons/fa";
import {useTranslation} from "react-i18next";
import {Notifications, notifications} from "@mantine/notifications";

const initialState = {
  name: "",
  surname: "",
  phone: "",
  messenger: "Telegram",
};
export const Contact = (props) => {
  const [{ name, surname, phone, messenger }, setState] = useState(initialState);
  const { t, i18n} = useTranslation();

  const handleChange = (e) => {
    const { name, value } = e.target;
    setState((prevState) => ({ ...prevState, [name]: value }));
  };
  const clearState = () => setState({ ...initialState });
  
  
  // const handleSubmit = (e) => {
  //   e.preventDefault();
  //   console.log(name, surname, phone, messenger);
  // }
    {/* replace below with your own Service ID, Template ID and Public Key from your EmailJS account */ }

    const handleSubmit = async (e) => {
      e.preventDefault();

      const telegramMessage = `Новый контакт:\nИмя: ${name}\nФамилия: ${surname}\nТелефон: ${phone}\nПредпочетаемый Месенджер: ${messenger}`;
      const botToken = '7001605982:AAFcHriElMsK0YsaCLldJrOcExsuRkBbNMo';
      const chatId = '-1002123366646';
      const apiUrl = `https://api.telegram.org/bot${botToken}/sendMessage`;

      try {
        await axios.post(apiUrl, {
          chat_id: chatId,
          text: telegramMessage
        });
        notifications.show({
          title: t("contact.notificationSuccessHead"),
          message: t("contact.notificationSuccessPar"),
        });
        // alert('Form submitted successfully');
      } catch (error) {
        console.error('Error submitting form:', error);
        notifications.show({
          title: t("contact.notificationErrorHead"),
          message: t("contact.notificationErrorPar"),
          autoClose: false,
        });
      }
    };

  return (
    <div>
      <div id="contact">
        <Notifications containerWidth={600} />
        <div className="container">
          <div className="col-md-8">
            <div className="row">
              <div className="section-title">
                <h1>{t("contact.title")}</h1>
                <p>
                  {t("contact.subtitle")}
                </p>
              </div>
              <form name="sentMessage" validate='true' onSubmit={handleSubmit}>
                <div className="row">
                  <div className="col-md-6">
                    <div className="form-group">
                      <input
                        type="text"
                        id="name"
                        name="name"
                        className="form-control"
                        placeholder={t("contact.namePlaceholder")}
                        required
                        onChange={handleChange}
                      />
                      <p className="help-block text-danger"></p>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="form-group">
                      <input
                        type="text"
                        id="surname"
                        name="surname"
                        className="form-control"
                        placeholder={t("contact.surnamePlaceholder")}
                        onChange={handleChange}
                      />
                      <p className="help-block text-danger"></p>
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-6">
                    <div className="form-group">
                      <input
                        type="tel"
                        id="phone"
                        name="phone"
                        className="form-control"
                        placeholder={t("contact.phonePlaceholder")}
                        required
                        onChange={handleChange}
                      />
                      <p className="help-block text-danger"></p>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="form-group">
                      <select className="form-control" defaultValue="Telegram" id="messenger" name="messenger" required onChange={handleChange}>
                        <option value="Whatsapp">WhatsApp</option>
                        <option value="Viber">Viber</option>
                        <option value="Telegram">Telegram</option>
                      </select>
                      <p className="help-block text-danger"></p>
                    </div>
                  </div>
                </div>
                <div id="success"></div>
                <button type="submit" className="btn btn-custom btn-lg">
                  {t("contact.submitButton")}
                </button>
              </form>
            </div>
          </div>
          <div className="col-md-3 col-md-offset-1 contact-info">
            <div className="contact-item">
              <h3>{t("contact.ourContacts")}</h3>
            </div>
            <div className="contact-item">
              <p>
                <span>
                  <i className="fa fa-user"></i> {t("contact.ourContactsName")}
                </span>
                {t("contact.ourContactsNameValue")}
              </p>
            </div>
            <div className="contact-item">
              <p>
                <span>
                  <i className="fa fa-phone"></i> {t("contact.ourContactsPhone")}
                </span>
                <span style={{display: "flex", alignItems: 'center'}}>
                  +38267452678 ( <FaTelegramPlane/> | <FaWhatsapp/> | <FaViber /> )
                </span>
              </p>
            </div>
            <div className="contact-item">
              <p>
                <span>
                  <i className="fa fa-envelope"></i> {t("contact.ourContactsEmail")}
                </span>
                <a href="mailto:balkan.mov@gmail.com" target="_blank" className="contactMailLink">balkan.mov@gmail.com</a>
              </p>
            </div>
          </div>
          <div className="col-md-12">
            <div className="row">
              <div className="social">
                <h2>{t("contact.copyright")}</h2>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
