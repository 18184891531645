import React, {useRef} from 'react';
import { Card } from './card';
import { useTranslation } from 'react-i18next';

export const WhatIsIt = (props) => {
  const { t, i18n } = useTranslation();
  const contRef = useRef();

  const data = [
    {
      heading: t("whatIsIt.cardTitle1"),
      imgSrc: "/img/aboutus/imgOne.svg",
      paragraph: t("whatIsIt.cardP1"),
    },
    {
      heading: t("whatIsIt.cardTitle2"),
      imgSrc: "/img/aboutus/imgTwo.svg",
      paragraph: t("whatIsIt.cardP2"),
    },
    {
      heading: t("whatIsIt.cardTitle3"),
      imgSrc: "/img/aboutus/imgThree.svg",
      paragraph: t("whatIsIt.cardP3"),
    },
  ];

  return (
    <div id="whatIsIt" className="text-center">
      <div className="container">
        <h1 ref={contRef}>{t("whatIsIt.title")}</h1>
        <Card data={data} contRef={contRef} />
      </div>
    </div>
  );
};