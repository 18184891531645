import React, { useRef, useEffect } from "react";
import { Flippy, FrontSide, BackSide } from "react-flippy";
import { Grid } from "@mantine/core";
import { useTranslation } from "react-i18next";

export const Principles = (props) => {
  const { t, i18n } = useTranslation();
  let flippy = useRef(null);
  let contRef = useRef();

  const values = [
    {
      num: "1",
      front: t("principles.front1"),
      back: t("principles.back1"),
    },
    {
      num: "2",
      front: t("principles.front2"),
      back: t("principles.back2"),
    },
    {
      num: "3",
      front: t("principles.front3"),
      back: t("principles.back3"),
    },
    {
      num: "4",
      front: t("principles.front4"),
      back: t("principles.back4"),
    },
    {
      num: "5",
      front: t("principles.front5"),
      back: t("principles.back5"),
    },
    {
      num: "6",
      front: t("principles.front6"),
      back: t("principles.back6"),
    },
    {
      num: "7",
      front: t("principles.front7"),
      back: t("principles.back7"),
    },
  ];


  useEffect(() => {
    const fadeInCards = () => {
      if (contRef.current && isInViewport(contRef.current)) {
        values.forEach((item, index) => {
          const card = document.getElementById(`flippy-${item.num}`);
          if (card) {
            setTimeout(() => {
              card.classList.add("pop-in");
            }, index * 200); // Adjust the delay time as needed
          }
        });
      }
    };

    const isInViewport = (element) => {
      const rect = element.getBoundingClientRect();
      return (
        rect.top >= 0 &&
        rect.left >= 0 &&
        rect.bottom <=
        (window.innerHeight || document.documentElement.clientHeight) &&
        rect.right <=
        (window.innerWidth || document.documentElement.clientWidth)
      );
    };

    window.addEventListener("scroll", fadeInCards);
    fadeInCards(); // Check on initial load

    return () => {
      window.removeEventListener("scroll", fadeInCards);
    };
  }, []);


  return (
    <div id="principles" className="text-center">
      <div className="container">
        <h1 ref={contRef}>{t("principles.title")}</h1>
        <Grid
          style={{
            display: "flex",
            justifyContent: "center",
          }}
        >
          {values.map((item) => (
            <Grid.Col span={{ base: 4, md: 3 }} key={item.num}>
              <Flippy
                flipOnHover={true}
                flipDirection="horizontal"
                ref={(r) => (flippy = r)}
                style={{
                  width: "300px",
                  height: "340px",
                  opacity: '0',
                }}
                id={`flippy-${item.num}`}
              >
                <FrontSide
                  style={{
                    backgroundColor: "#5d9cf9",
                    color: "#fff",
                    borderRadius: "10px",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    fontSize: "40px",
                    height: "340px",
                  }}
                >
                  {item.front}
                </FrontSide>
                <BackSide
                  style={{
                    backgroundColor: "#fff",
                    color: "#5d9cf9",
                    borderRadius: "10px",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    fontSize: "21px",
                    border: "1px dashed #5d9cf9",
                    height: "340px",
                  }}
                >
                  {item.back}
                </BackSide>
              </Flippy>
            </Grid.Col>
          ))}
        </Grid>
      </div>
    </div>
  );
};
