import React, {useEffect, useState} from "react";
import {useTranslation} from "react-i18next";

export const Diagram = (props) => {
  const { t, i18n } = useTranslation();
  const [rotation, setRotation] = useState(0);

  useEffect(() => {
    const handleScroll = () => {
      const scrollPosition = window.scrollY;
      setRotation(scrollPosition);
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);


  const imageStyle = {
    transform: `rotate(${rotation/2}deg)`,
    transition: 'transform .5s ease-out',
  };

  return (
    <div id="diagram" className="text-center">
      <div className="container">
        <img src={t("diagram.src")} alt="diagram" style={imageStyle}/>
      </div>
    </div>
  );
};